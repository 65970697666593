import React, { useEffect, useReducer } from "react";
import dynamic from "next/dynamic";
import Cover from "../components/Cover";
import WelcomeMessage from "../components/WelcomeMessage";
import { Place } from "../models/Place";
import api, { Event, Source } from "../api";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { newSession, Session } from "../session";
import reducer, { State, Screen } from "../reducer";
import { Container } from "@material-ui/core";

const RouteForm = dynamic(
  () => import(/* webpackChunkName: "route-form" */ "../components/RouteForm")
);
const ScheduleForm = dynamic(
  () =>
    import(/* webpackChunkName: "schedule-form" */ "../components/ScheduleForm")
);
const ContactForm = dynamic(
  () =>
    import(/* webpackChunkName: "contact-form" */ "../components/ContactForm")
);
const SuccessMessage = dynamic(
  () =>
    import(
      /* webpackChunkName: "success-message" */ "../components/SuccessMessage"
    )
);

const Index: React.FC<InferGetServerSidePropsType<
  typeof getServerSideProps
>> = ({ initialState, session, source, external_client_id }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setupBranch = async (key: string) => {
    await import(/* webpackChunkName: "branch" */ "branch-sdk");

    window.branch.init(key);
  };

  // Setup branch
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_BRANCH_KEY) {
      setupBranch(process.env.NEXT_PUBLIC_BRANCH_KEY);
    }
  }, []);

  // Send event to API.
  useEffect(() => {
    let event: Event | undefined;

    switch (state.screen) {
      case Screen.Route:
        event = "place";
        break;
      case Screen.Schedule:
        event = "schedule";
        break;
      case Screen.Contact:
        event = "user";
        break;
      default:
        event = undefined;
    }

    // For first screen it should send the process started and corresponding event if it applies.
    if (state.screen == initialState.screen) {
      api
        .sendEvent({ session_id: session.id, source })
        .then(() => {
          if (!event) return;

          api
            .sendEvent({ session_id: session.id, source, event })
            .catch(console.error);
        })
        .catch(console.error);
      // For all other screens it should sent appropiate event.
    } else {
      api
        .sendEvent({ session_id: session.id, source, event })
        .catch(console.error);
    }
  }, [state.screen, session.id, initialState.screen, source]);

  let component;

  switch (state.screen) {
    case Screen.Home:
      component = (
        <WelcomeMessage
          onNext={async () => {
            try {
              await api.sendInitialData({
                source,
                session_id: session.id,
              });
            } catch (err) {
              console.error(err);
            }

            dispatch({ type: "next" });
          }}
        />
      );
      break;
    case Screen.Cover:
      component = (
        <Cover
          onNext={async () => {
            try {
              await api.sendInitialData({
                session_id: session.id,
                external_client_id,
                source,
              });
            } catch (err) {
              console.error(err);
            }

            dispatch({ type: "next" });
          }}
        />
      );
      break;
    case Screen.Route:
      component = (
        <RouteForm
          initial={state.form}
          onNext={async (form) => {
            try {
              const { origin, destination } = form;

              const getAddress = (place: Place) => ({
                place: place.name,
                address: place.address.formatted,
                neighborhood: place.address.neighborhood,
                municipality: place.address.municipality,
                state: place.address.state,
                country: place.address.country,
                zipCode: place.address.zipCode,
              });

              await api.sendRoute({
                origin: {
                  address: getAddress(origin),
                  longitude: origin.longitude,
                  latitude: origin.latitude,
                },
                destination: {
                  address: getAddress(destination),
                  longitude: destination.longitude,
                  latitude: destination.latitude,
                },
                session_id: session.id,
                external_client_id,
                source,
              });
            } catch (err) {
              console.error(err);
            }

            dispatch({ type: "next", form });
          }}
        />
      );
      break;
    case Screen.Schedule:
      component = (
        <ScheduleForm
          initial={state.form}
          onPrevious={() => dispatch({ type: "previous" })}
          onNext={async (form) => {
            try {
              const { weekdays } = form;

              await api.sendSchedule({
                iso_weekdays: weekdays.map((w) => parseInt(w, 9)),
                session_id: session.id,
                external_client_id,
                source,
              });
            } catch (err) {
              console.error(err);
            }

            dispatch({ type: "next", form });
          }}
        />
      );
      break;
    case Screen.Contact:
      component = (
        <ContactForm
          initial={state.form}
          onPrevious={() => dispatch({ type: "previous" })}
          onNext={async (form) => {
            try {
              const { name, email } = form;

              await api.sendUser({
                user_name: name,
                user_email: email,
                session_id: session.id,
                external_client_id,
                source,
              });
            } catch (err) {
              console.error(err);
            }

            dispatch({ type: "next", form });
          }}
        />
      );
      break;
    case Screen.End:
      component = <SuccessMessage />;
      break;
  }

  return (
    <Container style={{ flex: 1, padding: 24 }} maxWidth="xs" component="main">
      {component}
    </Container>
  );
};

interface IndexProps {
  /**
   * Initial state of the application.
   */
  initialState: State;

  /**
   * Unique session information for this user.
   */
  session: Session;

  /**
   * Referrer
   */
  source: Source;

  /**
   * Client id of external referrer.
   */
  external_client_id?: string;
}

export const getServerSideProps: GetServerSideProps<IndexProps> = async ({
  query,
}) => {
  const session = newSession();

  if (query["_branch_match_id"]) {
    const { client_id } = query;

    return {
      props: {
        session,
        initialState: {
          screen: Screen.Route,
          form: {},
        },
        external_client_id: client_id as string,
        source: "uber",
      },
    };
  }

  return {
    props: {
      session,
      initialState: {
        screen: Screen.Home,
        form: {},
      },
      source: "web",
    },
  };
};

export default Index;

import { makeStyles, createStyles } from "@material-ui/core/styles";

/**
 * Common styles for components.
 */
export const useStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        height: "100%",
      },
      button: {
        [theme.breakpoints.down("sm")]: {
          marginTop: "auto",
        },
      },
      link: {
        fontSize: 14,
      },
    }),
  { name: "Common" }
);

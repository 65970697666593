import Button from "@material-ui/core/Button";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const SCREENSHOTS = [
  {
    alt: "Screenshot 1",
    srcSet:
      "/assets/screenshots/iphone-5-8-p-01.jpg, /assets/screenshots/iphone-5-8-p-01@2x.jpg 2x, /assets/screenshots/iphone-5-8-p-01@3x.jpg 3x",
    src: "/assets/screenshots/iphone-5-8-p-01.jpg",
  },
  {
    alt: "Screenshot 2",
    srcSet:
      "/assets/screenshots/iphone-5-8-p-02.jpg, /assets/screenshots/iphone-5-8-p-02@2x.jpg 2x, /assets/screenshots/iphone-5-8-p-02@3x.jpg 3x",
    src: "/assets/screenshots/iphone-5-8-p-02.jpg",
  },
  {
    alt: "Screenshot 3",
    srcSet:
      "/assets/screenshots/iphone-5-8-p-03.jpg, /assets/screenshots/iphone-5-8-p-03@2x.jpg 2x, /assets/screenshots/iphone-5-8-p-03@3x.jpg 3x",
    src: "/assets/screenshots/iphone-5-8-p-03.jpg",
  },
  {
    alt: "Screenshot 4",
    srcSet:
      "/assets/screenshots/iphone-5-8-p-04.jpg, /assets/screenshots/iphone-5-8-p-04@2x.jpg 2x, /assets/screenshots/iphone-5-8-p-04@3x.jpg 3x",
    src: "/assets/screenshots/iphone-5-8-p-04.jpg",
  },
  {
    alt: "Screenshot 5",
    srcSet:
      "/assets/screenshots/iphone-5-8-p-05.jpg, /assets/screenshots/iphone-5-8-p-05@2x.jpg 2x, /assets/screenshots/iphone-5-8-p-05@3x.jpg 3x",
    src: "/assets/screenshots/iphone-5-8-p-05.jpg",
  },
  {
    alt: "Screenshot 6",
    srcSet:
      "/assets/screenshots/iphone-5-8-p-06.jpg, /assets/screenshots/iphone-5-8-p-06@2x.jpg 2x, /assets/screenshots/iphone-5-8-p-06@3x.jpg 3x",
    src: "/assets/screenshots/iphone-5-8-p-06.jpg",
  },
];

const useStyles = makeStyles(
  {
    screenshots: {
      overflowX: "scroll",
      WebkitOverflowScrolling: "touch",
      msOverflowStyle: "-ms-autohiding-scrollbar",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&:focus:not(:focus-visible)": {
        outline: "none",
      },
    },
  },
  { name: "Cover" }
);

export interface CoverProps {
  /**
   * Call back fired when the user clicks the start button, it disables the button after click.
   */
  onNext: () => Promise<void>;
}

const Cover: React.FC<CoverProps> = ({ onNext }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Grid container spacing={3} direction="column" wrap="nowrap">
      <Grid item container spacing={3}>
        <Grid item>
          <img
            src="/assets/app.png"
            width="97"
            height="97"
            srcSet="/assets/app.png, /assets/app@2x.png 2x, /assets/app@3x.png 3x"
            alt="Urbvan"
          />
        </Grid>
        <Grid item xs>
          <Typography component="h1" variant="h6" style={{ fontWeight: 700 }}>
            Urbvan
          </Typography>
          <Typography color="textSecondary">
            Viajes accesibles a diario en la ruta que necesitas
          </Typography>
          <Typography>
            Reservar por{" "}
            <Link
              href="https://apps.apple.com/mx/app/urbvan/id1183481334"
              aria-label="Apple App Store"
              title="Apple App Store"
            >
              <FontAwesomeIcon
                icon={faApple}
                color={theme.palette.text.primary}
              />
            </Link>{" "}
            <Link
              href="https://play.google.com/store/apps/details?id=com.urbvan.android"
              aria-label="Google Play Store"
              title="Google Play Store"
            >
              <FontAwesomeIcon
                icon={faGooglePlay}
                size="xs"
                color={theme.palette.text.primary}
              />
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>
          Urbvan es la red de transporte en vans compartidas más segura y
          eficiente de la ciudad. Ingresa tu origen y destino y encuentra la
          ruta que se adapte a tus rumbos. Reserva tu lugar desde la app y viaja
          bajo las medidas de seguridad más completas.
        </Typography>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        wrap="nowrap"
        className={classes.screenshots}
        tabIndex={0}
      >
        {SCREENSHOTS.map((screenshot, index) => (
          <Grid item key={index}>
            <img width="144" height="311" {...screenshot} />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Typography component="h2" variant="h6" style={{ fontWeight: 700 }}>
          Sugiere aquí tu ruta ideal
        </Typography>
        <Typography>
          Ayúdanos a expandir nuestro catálogo de trayectos para llegar a los
          lugares donde viajas diariamente.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ChevronRight />}
          fullWidth
          disabled={isSubmitting}
          onClick={async () => {
            setSubmitting(true);

            if (onNext) await onNext();
          }}
        >
          Sugerir mi ruta
        </Button>
      </Grid>
    </Grid>
  );
};

export default Cover;

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../styles";
import { Link } from "@material-ui/core";

export interface WelcomeMessageProps {
  /**
   * Call back fired when the user clicks next button,
   * it disables the button after clicked.
   */
  onNext?: () => void;
}

/**
 * This is the welcome message.
 */
const WelcomeMessage: React.FC<WelcomeMessageProps> = ({ onNext }) => {
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      wrap="nowrap"
      className={classes.container}
    >
      <Grid item>
        <Typography component="h1" variant="h6" gutterBottom align="center">
          Sugiere tu ruta
        </Typography>
        <Typography component="h2" variant="h5" align="center">
          Gracias por ayudarnos a ampliar nuestra red, con tu ayuda llegaremos
          mas lejos
        </Typography>
      </Grid>
      <Grid item>
        <Box textAlign="center">
          <img
            src="/assets/pictures/welcome.svg"
            alt="Bienvenido"
            width="262"
            height="230"
          />
        </Box>
      </Grid>
      <Grid item className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={() => {
            setSubmitting(true);

            if (onNext) onNext();
          }}
          fullWidth
        >
          Empezar
        </Button>
      </Grid>
      <Grid item style={{ textAlign: "center" }}>
        <Link
          href="/politica-de-cookies"
          target="_blank"
          className={classes.link}
        >
          Política de Cookies
        </Link>
      </Grid>
    </Grid>
  );
};

export default WelcomeMessage;

import axios, { AxiosInstance } from "axios";

export type Source = "uber" | "web";

export type Event = "place" | "schedule" | "user";

export interface SendInitialDataBody {
  external_client_id?: string;

  session_id: string;

  source: Source;
}

export interface Address {
  address: string;

  country: string;

  municipality: string;

  neighborhood: string;

  place: string;

  state: string;

  zipCode: string;
}

export interface Place {
  address: Address;

  latitude?: number;

  longitude?: number;
}

export interface SendRouteBody {
  origin: Place;

  destination: Place;

  session_id: string;

  source: Source;

  external_client_id?: string;
}

export interface SendScheduleBody {
  iso_weekdays: number[];

  session_id: string;

  source: Source;

  external_client_id?: string;
}

export interface SendUserBody {
  user_email: string;

  user_name: string;

  session_id: string;

  source: Source;

  external_client_id?: string;
}

export interface SendEventBody {
  event?: Event;

  source: Source;

  session_id: string;
}

export class APIClient {
  private readonly http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_HOST,
      headers: {
        Authorization: `${process.env.NEXT_PUBLIC_API_TOKEN}`,
      },
    });
  }

  async sendInitialData(body: SendInitialDataBody): Promise<void> {
    await this.http.post("/suggestion/v3/", body);
  }

  async sendRoute(body: SendRouteBody): Promise<void> {
    await this.http.post("/suggestion/v3/place", body);
  }

  async sendSchedule(body: SendScheduleBody): Promise<void> {
    await this.http.post("/suggestion/v3/schedule", body);
  }

  async sendUser(body: SendUserBody): Promise<void> {
    await this.http.post("/suggestion/v3/user/", body);
  }

  async sendEvent(body: SendEventBody): Promise<void> {
    await this.http.post("/suggestion/v3/event/", body);
  }
}

const client = new APIClient();

export default client;

import { ContactFormValues } from "./schema/ContactFormSchema";
import { ScheduleFormValues } from "./schema/ScheduleFormSchema";
import { Route } from "./models/Route";

/**
 * Screens of the application
 */
export enum Screen {
  Home,
  Cover,
  Route,
  Schedule,
  Contact,
  End,
}

/**
 * State of the application.
 */
export type State = {
  /**
   * The current screen.
   */
  screen: Screen;

  /**
   * Form values.
   */
  form: Partial<Route & ContactFormValues & ScheduleFormValues>;
};

/**
 * Actions that can mutate the state.
 */
export type Action =
  | { type: "previous" }
  | { type: "next"; form?: State["form"] };

/**
 * Main reducer of the application, it stores every step of the form.
 *
 * @param state
 * @param action
 */
export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "previous":
      switch (state.screen) {
        case Screen.Schedule:
          return {
            ...state,
            screen: Screen.Route,
          };
        case Screen.Contact:
          return {
            ...state,
            screen: Screen.Route,
          };
        default: {
          return state;
        }
      }
    case "next":
      switch (state.screen) {
        case Screen.Home:
        case Screen.Cover:
          return {
            ...state,
            screen: Screen.Route,
          };
        case Screen.Route:
          return {
            ...state,
            screen: Screen.Contact,
            form: {
              ...state.form,
              ...action.form,
            },
          };
        case Screen.Schedule:
          return {
            ...state,
            screen: Screen.Contact,
            form: {
              ...state.form,
              ...action.form,
            },
          };
        case Screen.Contact:
          return {
            ...state,
            screen: Screen.End,
            form: {
              ...state.form,
              ...action.form,
            },
          };
        default:
          return state;
      }
    default:
      return state;
  }
}
